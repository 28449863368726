/* General container styling */
.social-container {
    min-height: 100vh;
    background-color: #f7fafc;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .social-content {
    max-width: 900px;
    width: 100%;
    text-align: center;
  }
  
  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1a202c;
    margin-bottom: 20px;
    text-align: center;
  }
  
  p {
    font-size: 1rem;
    color: #718096;
    margin-bottom: 40px;
  }
  
  .social-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .social-card {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    transition: box-shadow 0.3s ease;
    transform: translateY(0);
  }
  
  .social-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-3px);
  }
  
  /* Social media icon and text */
  .social-card svg {
    margin-bottom: 10px;
  }
  
  .social-card span {
    font-size: 1.1rem;
    font-weight: 500;
    color: #2d3748;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 640px) {
    h1 {
      font-size: 2rem;
    }
  
    p {
      font-size: 0.95rem;
    }
  
    .social-card span {
      font-size: 1rem;
    }
  
    .social-card {
      padding: 15px;
    }
  }
  
.hmid
{
    margin-top: 69px;
    margin-bottom: 0;
}
.mmanj
{
    color: #D19200;

/* head4 */
font-family: "Bai Jamjuree";
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.mneel
{
    color: #008FD4;

/* head4 */
font-family: "Bai Jamjuree";
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.mdesc
{
    width: 563px;
    color: #04324D;
text-align: center;
font-family: "Bai Jamjuree";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 0px;
}
.hcount
{
    display: inline-flex;
align-items: center;
justify-content: center;
gap: 158px;
background: linear-gradient(180deg, rgba(0, 168, 233, 0.80) 0%, #00A8E9 100%);
width: 100%;
margin-top: 49px;
}
.icount
{
    display:block;
}
.icount h3{
    color: #EFFAFF;
text-align: center;
font-family: "Bai Jamjuree";
font-size: 75px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 0;
}
.icount p{
    color: #EFFAFF;
text-align: center;
font-family: "Bai Jamjuree";
font-size: 21px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.memb
{
    background: #FFFEE7;
    padding-bottom: 79px;
}
.joinb
{
    width:220px;
    text-align: left;
    border-radius: 45px;
background: #FFF686;
box-shadow: 0px 4px 36.9px 3px rgba(68, 34, 4, 0.25);
padding: 30px;
display: block;
margin-left: auto;
margin-right: auto;
align-items: center;
margin-top: 50px;

}
.joinb p{
    width: 180px;
    color: #442204;
font-family: "Bai Jamjuree";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1.6px;
}
.joinb h2{
    color: #442204;
font-family: "Bai Jamjuree";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 2.16px;
}
.fefe
{
    color: #442204;
font-family: "Bai Jamjuree";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 2.16px;
width: 200px;
}
.evind{
    width: 50%;
    display: flex;
    gap: 33px;
    border-radius: 20px;
background: #DEF3FF;
box-shadow: -10px 4px 28.1px 5px rgba(4, 50, 77, 0.25);
padding: 17px;
}
.evind1
{
    display: block;
    text-align: left;
    
}
.evind1 h2{
    color: #04324D;
font-family: "Bai Jamjuree";
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: 0;
}
.evind1 p{
    color: #04324D;
font-family: "Bai Jamjuree";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.evind1 button{
    display: inline-flex;
padding: 7px 81px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 9px;
border: 1px solid #04324D;
background: #04324D;
color: #EFFAFF;
font-family: "Bai Jamjuree";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.incev
{
    padding-bottom: 110px;
}
.neel, .lneel, .manj {
    color: #04324D;
    font-family: "Bai Jamjuree", sans-serif;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
  }
  
  .lneel { color: #0071AB; }
  .manj { color: #D19200; }
  
  .iebann {
    background-image: url("../../images/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 100px 20px;
    min-height: 400px;
    display: flex;
    align-items: center;
  }
  
  .banner-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .banner-line {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .myStyle {
    color: #008FD4;
    font-family: "Bai Jamjuree", sans-serif;
    font-size: 1.3rem;
    font-weight: 700;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .neel, .lneel, .manj {
      font-size: 2rem;
    }
  
    .myStyle {
      font-size: 1rem;
    }
  
    .iebann {
      padding: 60px 20px;
      min-height: 300px;
    }
  }
.foots
{
    border-radius: 150px 0px 0px 0px;
background: #04324D;
margin: 0;
}
.foots h2{
    color: #EFFAFF;
font-family: "Bai Jamjuree";
font-size: 51px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: left;
padding-left: 90px;
padding-top: 195px;
padding-bottom: 131px;
}
.footi
{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.logdet{
    text-align: left;
    color: #EFFAFF;
font-family: "Bai Jamjuree";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.linkdet a
{
    text-decoration: none;
    display: block;
    color: #EFFAFF;
font-family: "Bai Jamjuree";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 30px;
}
.ajs
{
    color: #EFFAFF;
text-align: center;
font-family: "Bai Jamjuree";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 0;
padding-bottom: 100px;
padding-top: 100px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* alus css */
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
.bai-jamjuree-extralight {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.bai-jamjuree-light {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.bai-jamjuree-regular {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.bai-jamjuree-medium {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.bai-jamjuree-semibold {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.bai-jamjuree-bold {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.bai-jamjuree-extralight-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.bai-jamjuree-light-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.bai-jamjuree-regular-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.bai-jamjuree-medium-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.bai-jamjuree-semibold-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.bai-jamjuree-bold-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 700;
  font-style: italic;
}
body{
  background: #EFFAFF;
}